import "./index.scss";

function IndividualisedLivingDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Your Pathway to Tailored Living Solutions</h1>
        <p>
          Many individuals with disabilities require support to live
          independently and engage with their communities. However, not everyone
          prefers living in a group home. Individualised Living Options (ILO)
          puts you in charge by offering flexible choices that align with your
          unique lifestyle.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>
        <p>
          Our supportive team will help you design the best ILO plan that fits
          your life and then take care of everything to ensure timely and
          effective delivery of the right services for you and those around you.
        </p>
        <h2>1. Co-Residency:</h2>
        <ul>
          <li>
            Specialized care focusing on post-surgical wound care and effective
            pain relief strategies
          </li>
        </ul>
        <h2>2. Host Arrangement:</h2>
        <ul>
          <li>
            Stay with another person, couple, or family who hosts you in their
            home and offers some care. The host receives NDIS funding to support
            you.
          </li>
        </ul>
        <h2>3. Living Alone:</h2>
        <ul>
          <li>
            Live independently in your own home, receiving the support you need.
          </li>
        </ul>

        <h2>4. Living Together:</h2>
        <ul>
          <li>
            Share a home with someone you already know, similar to traditional
            housemates, and decide to live together.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default IndividualisedLivingDetails;
