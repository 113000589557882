import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import AccommodationSectionBanner from "./bannner-section";
import AccommodationDetails from "./details-section";
import SEO from "../../SEO";

function AccommodationSection() {
  return (
    <>
      <SEO
        title="Accomodation Support"
        description="Guidance in finding suitable housing and managing accommodation needs with ongoing support."
        keywords="guidance, suitable, housing, managing, accomodation, support"
        image="../../../assets/images/portrait-disabled-person-dressed-yellow-wheelchair-school-smiling.png"
        url="https://www.famycare.com.au/accomodation-support"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <AccommodationSectionBanner />
          <AccommodationDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AccommodationSection;
