import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from "react";
import AccomodationIconImg from "../../../assets/images/icons/accomodation_icon_img.svg";
import NursingIconImg from "../../../assets/images/icons/nursing_icon_img.svg";
import DailyLifeIconImg from "../../../assets/images/icons/dailylife_icon_img.svg";
import SupportedIconImg from "../../../assets/images/icons/supported_icon_img.svg";
import IndividualisedIconImg from "../../../assets/images/icons/individual_icon_img.svg";
import HospitalIconImg from "../../../assets/images/icons/hospital_icon_img.svg";
import CommunityIconImg from "../../../assets/images/icons/community_icon_img.svg";
import LifeSkillsIconImg from "../../../assets/images/icons/lifeskills_icon_img.svg";
import AccomodationImg from "../../../assets/images/accomodation_img.png";
import HospitalImg from "../../../assets/images/hospital_img.png";
import NursingImg from "../../../assets/images/nursing_img.png";
import DailyImg from "../../../assets/images/daily_img.png";
import LifeImg from "../../../assets/images/life_img.png";
import IndividualImg from "../../../assets/images/individual_img.png";
import CommunityImg from "../../../assets/images/community_img.png";
import SupportedImg from "../../../assets/images/support_img.png";
import "./index.scss";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1.1,
  slidesToScroll: 1,
};

const services = [
  {
    service_id: 0,
    service_img: AccomodationImg,
    service_icon_img: AccomodationIconImg,
    service_title: "Accomodation Support",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/accomodation-support",
  },
  {
    service_id: 1,
    service_img: DailyImg,
    service_icon_img: DailyLifeIconImg,
    service_title: "Assistance in Daily Living",
    service_content:
      "Support with essential daily tasks like cooking, cleaning, grooming, and medication management",
    service_link: "/assistance-in-daily-living-service",
  },
  {
    service_id: 2,
    service_img: LifeImg,
    service_icon_img: LifeSkillsIconImg,
    service_title: "Development of Life Skills",
    service_content:
      "Programs to develop social, communication, and financial management skills for improved independence and self-sufficiency.",
    service_link: "/development-of-life-skills-service",
  },
  {
    service_id: 3,
    service_img: HospitalImg,
    service_icon_img: HospitalIconImg,
    service_title: "Hospital to Home Services",
    service_content:
      "Seamless transition from hospital to home with personalized care plans and recovery support.",
    service_link: "/hospital-to-home-service",
  },
  {
    service_id: 4,
    service_img: IndividualImg,
    service_icon_img: IndividualisedIconImg,
    service_title: "Individualised Living Options (ILO)",
    service_content:
      "Customized support arrangements that align with your lifestyle and help you live comfortably and independently.",
    service_link: "/individualised-living-service",
  },

  {
    service_id: 5,
    service_img: NursingImg,
    service_icon_img: NursingIconImg,
    service_title: "Nursing & Complex Care",
    service_content:
      "Comprehensive healthcare support, including wound care, medication management, and medical equipment assistance.",
    service_link: "/nursing-care",
  },
  {
    service_id: 6,
    service_img: CommunityImg,
    service_icon_img: CommunityIconImg,
    service_title: "Participation in Community",
    service_content:
      "Opportunities to engage in social, recreational, and community activities, building relationships and confidence",
    service_link: "/participation-in-community-service",
  },
  {
    service_id: 7,
    service_img: SupportedImg,
    service_icon_img: SupportedIconImg,
    service_title: "Supported Independent Living",
    service_content:
      "Tailored support for daily activities and personal care, promoting independent living in shared or individual homes.",
    service_link: "/supported-independent-living-service",
  },
];

function ServicesSection() {
  const navigate = useNavigate();

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="services-section-pc-container">
          <div className="services-header">
            <h3>Our Services</h3>
          </div>
          <div
            className="d-flex justify-content-between align-items-center flex-wrap"
            style={{
              gap: "6.875rem 1.5rem",
              boxShadow: "0 4px 4px rgba($color: #000000, $alpha: 0.25",
            }}
          >
            {services.map((e: any) => {
              return (
                <Card
                  key={e.service_id}
                  sx={{ width: "19%", borderRadius: "1rem", cursor: "pointer" }}
                  onClick={() => navigate(e.service_link)}
                >
                  <CardMedia
                    sx={{
                      height: "15.125rem",
                      width: "100%",
                      aspectRatio: "16/9",
                      objectFit: "cover",
                    }}
                    image={e.service_img}
                  />
                  <CardContent sx={{ minHeight: "10.5rem" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="service-card-title"
                    >
                      {e.service_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="service-card-content"
                    >
                      {e.service_content}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
        <div className="services-section-mobile-container">
          <div className="services-header">
            <h3>Our Services</h3>
          </div>
          <Slider {...settings}>
            {services.map((e: any) => {
              return (
                <Card
                  key={e.service_id}
                  sx={{ width: "19%", borderRadius: "1rem" }}
                >
                  <CardMedia
                    sx={{
                      height: "15.125rem",
                      width: "100%",
                      aspectRatio: "16/9",
                      objectFit: "cover",
                    }}
                    image={e.service_img}
                  />
                  <CardContent sx={{ minHeight: "9rem" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="service-card-title"
                    >
                      {e.service_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="service-card-content"
                    >
                      {e.service_content}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ServicesSection;
