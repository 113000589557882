import React from "react";
import IndividualisedLivingImg from "../../../../assets/images/pexels-cliff-booth-4058218 1.png";
import IndividualisedLivingMobileImg from "../../../../assets/images/pexels-cliff-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function IndividualisedLivingBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Individualised Living Options (ILO)</h1>

          <h2>
            Customized support arrangements that align with your lifestyle and
            help you live comfortably and independently.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={IndividualisedLivingImg} alt="" className="desktop_image" />
          <img
            src={IndividualisedLivingMobileImg}
            alt=""
            className="mobile_image"
          />
        </div>
      </div>
    </div>
  );
}

export default IndividualisedLivingBanner;
