import React from "react";
import HeroSectionImg from "../../../assets/images/hero_section_bg.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";

function HeroSection() {
  const handleMail = () => {
    window.location.href = "mailto:info@famycare.com.au";
  };

  const handleCall = () => {
    window.location.href = "tel:+61475977457";
  };

  return (
    <>
      <div className="hero-section-pc-container">
        <div className="hero-left">
          <div className="hero-main-header mb-3">
            <h3>Leading Support for Brighter Futures</h3>
          </div>
          <div className="hero-subtext mb-5">
            <h5>
              Famycare, your trusted Registered NDIS provider offers comprehensive
              disability services and 24/7 on-call registered nurse support in{" "}
              <strong>Greater Melbourne and Regional Victoria. </strong> We are
              dedicated to empowering participants to lead, make independent
              choices, and pursue their goals confidently.
            </h5>
          </div>
          <div className="hero-footer-text">
            <h4>
              "We care for everyone, making a difference with compassion."
            </h4>
          </div>
        </div>
        <div className="hero-right">
          <img src={HeroSectionImg} alt="" className="w-100" />
        </div>
      </div>
      <div className="hero-section-mobile-container">
        <div className="hero-mobile-text">
          <div className="hero-main-header mb-3">
            <h3>Leading Support for Brighter Futures</h3>
          </div>
          <div className="hero-subtext mb-5">
            <h5>
              Famycare, your trusted Registered NDIS provider offers comprehensive
              disability services and 24/7 on-call registered nurse support in{" "}
              <strong>Greater Melbourne and Regional Victoria. </strong> We are
              dedicated to empowering participants to lead, make independent
              choices, and pursue their goals confidently.
            </h5>
          </div>
          <div className="hero-footer-text">
            <h4>
              "We care for everyone, making a difference with compassion."
            </h4>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ paddingBottom: "2.0625rem" }}
        >
          <Button
            variant="contained"
            className="me-2 message-btn"
            onClick={handleMail}
          >
            <div className="d-flex align-items-center">
              <img src={MessageIcon} style={{ marginRight: "10px" }} alt="" />
              <h4 className="button-text">Email Us</h4>
            </div>
          </Button>
          <Button variant="contained" className="call-btn" onClick={handleCall}>
            <div className="d-flex align-items-center">
              <img src={CallIcon} style={{ marginRight: "10px" }} alt="" />
              <h4 className="button-text">Call Now</h4>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
