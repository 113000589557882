import React from "react";
import Navbar from "../../Navbar";
import AssistSection from "../../Home/AssistSection";
import Footer from "../../Home/Footer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./index.scss";

function NDISPricingChanges() {
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    navigate(e);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#025D68"
      href="/blog"
      sx={{
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: "1rem",
        lineHeight: "110%",
      }}
      onClick={() => handleClick("/blog")}
    >
      Blogs
    </Link>,

    <Typography
      key="3"
      color="text.primary"
      sx={{
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: "1rem",
        lineHeight: "110%",
      }}
    >
      NDIS pricing changes
    </Typography>,
  ];

  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="ndis-section-pc-container">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ marginBottom: "2.5rem" }}
            >
              {breadcrumbs}
            </Breadcrumbs>

            <h3 className="ndis-section-header">
              NDIS pricing changes for 2023-24: what you need to know
            </h3>

            <h4 className="ndis-section-date">1st July, 2023</h4>

            <h4>
              The National Disability Insurance Agency (NDIA) has released its
              pricing arrangements and price limits for the year 2023-24,
              following their Annual Pricing Review. Effective from July 1st,
              2023, the following pricing adjustments will come into effect.
              NDIS participants’ plans will also be indexed to accommodate these
              changes.
            </h4>

            <h4>
              To provide a concise overview, here are the key points regarding
              the NDIS pricing updates:
            </h4>
            <h4>
              Price limits for disability support workers: Price limits for
              support delivered by disability support workers will increase by
              5.3%. This adjustment accounts for several factors, including:
            </h4>
            <ul>
              <li>
                the Fair Work Commission’s decision to increase award wages,
              </li>
              <li>
                an extension of the temporary loading at 1% for an additional 12
                months (scheduled to conclude on June 30th, 2024),
              </li>
              <li>
                a 0.1% increase to reflect the inclusion of paid family and
                domestic violence leave in the Disability Support Worker Cost
                Model,
              </li>
              <li>
                and a 0.5% increase passed on due to the Superannuation
                Guarantee Charge.
              </li>
            </ul>

            <h4>
              <strong>Therapy supports:</strong> No changes will be made to the
              pricing arrangements and price limits for therapy supports. The
              NDIA believes that the current pricing structure remains
              competitive in the market, ensuring continued access to therapy
              services for NDIS participants.
            </h4>

            <h4>
              <strong>Plan management and support coordination:</strong> Level 1
              support coordination will see a 5.3% increase, reflecting the
              evolving nature of this critical service. However, there will be
              no adjustments to the price limits for support coordination level
              2 and 3, or for plan management supports.
            </h4>

            <h4>
              <strong>Temporary transformation payment (TTP) ending:</strong>{" "}
              The TTP, introduced to support providers with the transition to
              the NDIS, will cease from July 1st, 2024. As of July 1st, 2023,
              the TTP loading will be reduced from 3% to 1.5%.
            </h4>

            <h4>
              <strong>Other supports:</strong> The price limit for other
              supports will be increased by 4.36%, aligning with the NDIA’s
              commitment to fair and sustainable pricing across all support
              categories.
            </h4>

            <h4>
              These changes aim to improve the quality and sustainability of
              services provided under the NDIS. It is important to review the
              complete details of these pricing changes on the NDIA’s website.
              You can find more information about the NDIS Pricing Arrangements
              and Price Limits here.
            </h4>

            <h4>
              If you have any questions or concerns about how the new pricing
              arrangements and price limits might impact you, reach out to our
              friendly team for support.
            </h4>
          </div>
          <div className="ndis-section-mobile-container">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ marginBottom: "2.5rem" }}
            >
              {breadcrumbs}
            </Breadcrumbs>

            <h3 className="ndis-section-header">
              NDIS pricing changes for 2023-24: what you need to know
            </h3>

            <h4 className="ndis-section-date">1st July, 2023</h4>

            <h4>
              The National Disability Insurance Agency (NDIA) has released its
              pricing arrangements and price limits for the year 2023-24,
              following their Annual Pricing Review. Effective from July 1st,
              2023, the following pricing adjustments will come into effect.
              NDIS participants’ plans will also be indexed to accommodate these
              changes.
            </h4>

            <h4>
              To provide a concise overview, here are the key points regarding
              the NDIS pricing updates:
            </h4>
            <h4>
              Price limits for disability support workers: Price limits for
              support delivered by disability support workers will increase by
              5.3%. This adjustment accounts for several factors, including:
            </h4>
            <ul>
              <li>
                the Fair Work Commission’s decision to increase award wages,
              </li>
              <li>
                an extension of the temporary loading at 1% for an additional 12
                months (scheduled to conclude on June 30th, 2024),
              </li>
              <li>
                a 0.1% increase to reflect the inclusion of paid family and
                domestic violence leave in the Disability Support Worker Cost
                Model,
              </li>
              <li>
                and a 0.5% increase passed on due to the Superannuation
                Guarantee Charge.
              </li>
            </ul>

            <h4>
              <strong>Therapy supports:</strong> No changes will be made to the
              pricing arrangements and price limits for therapy supports. The
              NDIA believes that the current pricing structure remains
              competitive in the market, ensuring continued access to therapy
              services for NDIS participants.
            </h4>

            <h4>
              <strong>Plan management and support coordination:</strong> Level 1
              support coordination will see a 5.3% increase, reflecting the
              evolving nature of this critical service. However, there will be
              no adjustments to the price limits for support coordination level
              2 and 3, or for plan management supports.
            </h4>

            <h4>
              <strong>Temporary transformation payment (TTP) ending:</strong>{" "}
              The TTP, introduced to support providers with the transition to
              the NDIS, will cease from July 1st, 2024. As of July 1st, 2023,
              the TTP loading will be reduced from 3% to 1.5%.
            </h4>

            <h4>
              <strong>Other supports:</strong> The price limit for other
              supports will be increased by 4.36%, aligning with the NDIA’s
              commitment to fair and sustainable pricing across all support
              categories.
            </h4>

            <h4>
              These changes aim to improve the quality and sustainability of
              services provided under the NDIS. It is important to review the
              complete details of these pricing changes on the NDIA’s website.
              You can find more information about the NDIS Pricing Arrangements
              and Price Limits here.
            </h4>

            <h4>
              If you have any questions or concerns about how the new pricing
              arrangements and price limits might impact you, reach out to our
              friendly team for support.
            </h4>
          </div>
        </div>
      </div>
      <AssistSection />
      <Footer />
    </>
  );
}

export default NDISPricingChanges;
