import React from "react";
import LifeSkillsImg from "../../../../assets/images/lifeskills.png";
import LifeSkillsMobileImg from "../../../../assets/images/lifeskills-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function LifeSkillsDevelopmentBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Development of Life Skills</h1>

          <h2>
            Programs to develop social, communication, and financial management
            skills for improved independence and self-sufficiency.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={LifeSkillsImg} alt="" className="desktop_image" />
          <img src={LifeSkillsMobileImg} alt="" className="mobile_image" />
        </div>
      </div>
    </div>
  );
}

export default LifeSkillsDevelopmentBanner;
