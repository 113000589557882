import React from "react";
import HospitalToHomeImg from "../../../../assets/images/helping-handicapped-girlfriend (1) 1.png";
import HospitalToHomeMobileImg from "../../../../assets/images/helping-handicapped-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function HospitalToHomeProgramBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Hospital to Home Services</h1>

          <h2>
            Seamless transition from hospital to home with personalized care
            plans and recovery support.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={HospitalToHomeImg} alt="" className="desktop_image" />
          <img src={HospitalToHomeMobileImg} alt="" className="mobile_image" />
        </div>
      </div>
    </div>
  );
}

export default HospitalToHomeProgramBanner;
