import React from "react";
import AccommodationImg from "../../../../assets/images/portrait-disabled-person-dressed-yellow-wheelchair-school-smiling.png";
import AccommodationMobileImg from "../../../../assets/images/portrait-disabled-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function AccommodationSectionBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Accomodation Support</h1>

          <h2>
            Guidance in finding suitable housing and managing accommodation
            needs with ongoing support.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={AccommodationImg} alt="" className="desktop_image" />
          <img src={AccommodationMobileImg} alt="" className="mobile_image" />
        </div>
      </div>
    </div>
  );
}

export default AccommodationSectionBanner;
