import Card from "@mui/material/Card";
import React from "react";
import HospitalIcon from "../../assets/images/hospital_icon.png";
import NDISIcon from "../../assets/images/ndis_icon.png";
import Navbar from "../Navbar";
import AssistSection from "../Home/AssistSection";
import Footer from "../Home/Footer";
import "./index.scss";
import SEO from "../SEO";

function AboutUs() {
  return (
    <>
      <SEO
        title="About FamyCare"
        description="We provide disability support and nursing care to NDIS participants in Greater Melbourne and Regional Victoria."
        keywords="support, nursing, Melbourne, Victoria"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.famycare.com.au/about-us"
      />
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="about-section-pc-container">
            <h3 className="about-us-header">About Famycare</h3>
            <div className="d-flex justify-content-between align-items-center about-first-section">
              <h4 className="about-us-subtext">
                Founded and operated by Registered Nurses. We are professionally
                qualified, experienced, and trained teams with over 20 years of
                experience in nursing and disability support in the healthcare
                and disability sectors. Famycare has been at the forefront of
                nursing and disability support, delivering expert care and
                genuine compassion in every interaction.
              </h4>
              <div>
                <Card className="hospital-card">
                  <div className="text-center hospital-card-inner">
                    <img src={HospitalIcon} alt="" />
                    <p>Founded and operated By</p>
                    <h5>Registered Nurses</h5>
                    <p>Following COVID-19 Procedures</p>
                  </div>
                </Card>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center about-second-section">
              <h4 className="about-final-subtext">
                We provide disability support and nursing care to NDIS
                participants in{" "}
                <strong>Greater Melbourne and Regional Victoria.</strong>{" "}
              </h4>
            </div>
          </div>
          <div className="about-section-mobile-container">
            <h3 className="about-us-header">About Famycare</h3>
            <div className="d-flex flex-column justify-content-between align-items-center about-first-section">
              <h4 className="about-us-subtext">
                Founded and operated by Registered Nurses. We are professionally
                qualified, experienced, and trained teams with over 20 years of
                experience in nursing and disability support in the healthcare
                and disability sectors. Famycare has been at the forefront of
                nursing and disability support, delivering expert care and
                genuine compassion in every interaction.
              </h4>
              <div>
                <Card className="hospital-card">
                  <div className="text-center hospital-card-inner">
                    <img src={HospitalIcon} alt="" />
                    <p>Founded and operated By</p>
                    <h5>Registered Nurses</h5>
                    <p>Following COVID-19 Procedures</p>
                  </div>
                </Card>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center about-second-section">
              <h4 className="about-final-subtext">
                We provide disability support and nursing care to NDIS
                participants in{" "}
                <strong>Greater Melbourne and Regional Victoria.</strong>{" "}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <AssistSection />
      <Footer />
    </>
  );
}

export default AboutUs;
