import React from "react";
import AccommodationImg from "../../../../assets/images/portrait-disabled-person-dressed-yellow-wheelchair-school-smiling.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import HospitalToHomeProgramBanner from "./banner-section";
import AssistSection from "../../Home/AssistSection";
import Footer from "../../Home/Footer";
import HospitalToHomeDetails from "./details-section";
import SEO from "../../SEO";

function HospitalToHomeProgram() {
  return (
    <>
      <SEO
        title="Hospital to Home Services"
        description="Seamless transition from hospital to home with personalized care plans and recovery support."
        keywords="seamless, transition, hospital, home, care, recovery"
        image="../../../assets/images/helping-handicapped-girlfriend (1) 1.png"
        url="https://www.famycare.com.au/hospital-to-home-service"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <HospitalToHomeProgramBanner />
          <HospitalToHomeDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HospitalToHomeProgram;
