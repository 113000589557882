import React from "react";
import Navbar from "../Navbar";
import AssistSection from "../Home/AssistSection";
import Footer from "../Home/Footer";
import CareerImg from "../../assets/images/career_img.png";
import CareerMobileImg from "../../assets/images/career_mobile_img.png";
import MailImg from "../../assets/images/mail_gif.gif";
import "./index.scss";
import SEO from "../SEO";

function Career() {
  return (
    <>
      <SEO
        title="Career"
        description="Join Our Mission to Make a Difference"
        keywords="join, mission, difference"
        image="../../assets/images/career_img.png"
        url="https://www.famycare.com.au/career"
      />
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="career-section-pc-container">
            <div className="career-top-section">
              <div className="career-left">
                <div className="career-main-header mb-3">
                  <h3>Join Our Mission to Make a Difference</h3>
                </div>
                <div className="career-subtext mb-5">
                  <h5>
                    Empower lives daily with comfort and joy. Start your journey
                    in transformative care with us.
                  </h5>
                </div>
              </div>
              <div className="career-right">
                <img src={CareerImg} alt="" className="w-100" />
              </div>
            </div>
            <div className="career-bottom-section">
              <img src={MailImg} alt="" />
              <h4>
                Please send your resume to{" "}
                <a href="mailto: info@famycare.com.au" className="mail-link">
                  info@famycare.com.au
                </a>
              </h4>
            </div>
          </div>
          <div className="career-section-mobile-container">
            <div className="career-top-section">
              <div className="career-left">
                <div className="career-main-header mb-3">
                  <h3>Join Our Mission to Make a Difference</h3>
                </div>
                <div className="career-subtext mb-5">
                  <h5>
                    Empower lives daily with comfort and joy. Start your journey
                    in transformative care with us.
                  </h5>
                </div>
              </div>
              <div className="career-right">
                <img src={CareerMobileImg} alt="" className="w-100" />
              </div>
            </div>
            <div className="career-bottom-section">
              <img src={MailImg} alt="" />
              <h4>
                Please send your resume to{" "}
                <a href="mailto: info@famycare.com.au" className="mail-link">
                  info@famycare.com.au
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Career;
