import React, { FormEvent, useState } from "react";
import Navbar from "../Navbar";
import AssistSection from "../Home/AssistSection";
import Footer from "../Home/Footer";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { custom_post, post } from "../../../utils/api";
import Button from "@mui/material/Button";
import "./index.scss";
import SEO from "../SEO";
import InputLabel from "@mui/material/InputLabel";
import AccomodationIconImg from "../../assets/images/icons/accomodation_icon_img.svg";
import NursingIconImg from "../../assets/images/icons/nursing_icon_img.svg";
import DailyLifeIconImg from "../../assets/images/icons/dailylife_icon_img.svg";
import SupportedIconImg from "../../assets/images/icons/supported_icon_img.svg";
import IndividualisedIconImg from "../../assets/images/icons/individual_icon_img.svg";
import HospitalIconImg from "../../assets/images/icons/hospital_icon_img.svg";
import CommunityIconImg from "../../assets/images/icons/community_icon_img.svg";
import LifeSkillsIconImg from "../../assets/images/icons/lifeskills_icon_img.svg";
import AccomodationImg from "../../assets/images/accomodation_img.png";
import HospitalImg from "../../assets/images/hospital_img.png";
import NursingImg from "../../assets/images/nursing_img.png";
import DailyImg from "../../assets/images/daily_img.png";
import LifeImg from "../../assets/images/life_img.png";
import IndividualImg from "../../assets/images/individual_img.png";
import CommunityImg from "../../assets/images/community_img.png";
import SupportedImg from "../../assets/images/support_img.png";
import SnackbarAlert from "../../../common/sanckbar";

const serviceData = [
  {
    service_id: 0,
    service_img: AccomodationImg,
    service_icon_img: AccomodationIconImg,
    service_title: "Accomodation Support",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/accomodation-support",
  },
  {
    service_id: 1,
    service_img: CommunityImg,
    service_icon_img: CommunityIconImg,
    service_title: "Participation in Community",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/participation-in-community-service",
  },
  {
    service_id: 2,
    service_img: DailyImg,
    service_icon_img: DailyLifeIconImg,
    service_title: "Assistance in Daily Living",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/assistance-in-daily-living-service",
  },
  {
    service_id: 3,
    service_img: HospitalImg,
    service_icon_img: HospitalIconImg,
    service_title: "Hospital to Home Services",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/hospital-to-home-service",
  },
  {
    service_id: 4,
    service_img: IndividualImg,
    service_icon_img: IndividualisedIconImg,
    service_title: "Individualised Living Options (ILO)",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/individualised-living-service",
  },
  {
    service_id: 5,
    service_img: LifeImg,
    service_icon_img: LifeSkillsIconImg,
    service_title: "Development of Life Skills",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/development-of-life-skills-service",
  },
  {
    service_id: 6,
    service_img: NursingImg,
    service_icon_img: NursingIconImg,
    service_title: "Nursing & Complex Care",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/nursing-care",
  },
  {
    service_id: 7,
    service_img: SupportedImg,
    service_icon_img: SupportedIconImg,
    service_title: "Supported Independent Living",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/supported-independent-living-service",
  },
];

function ContactUs() {
  const [initialFormData, setInitialFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    service_required: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    full_name: "",
    phone: "",
    email: "",
    service_required: "",
    message: "",
  });

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [selectedService, setSelectedService] = useState<any>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (!/^\d*$/.test(value)) {
        alert("Phone number can only contain numbers", "warning");
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone: string) => {
    return phone.length >= 10;
  };

  const handleContactForm = async (e: FormEvent) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address", "warning");
      return;
    }

    if (!validatePhoneNumber(formData.phone)) {
      alert(
        "Please enter a valid phone number with at least 10 digits",
        "warning"
      );
      return;
    }

    try {
      const response = await post("send-email", { ...formData });

      if (response) {
        // Reset the form data
        setFormData(initialFormData);
        console.log("Form submitted successfully");
        alert("Form submitted successfully", "success");
      } else {
        console.error("Form submission failed");
        alert("Form submission failed", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form", "error");
    }
  };

  const handleServiceChange = (e: any) => {
    console.log("newService", e.target.value);
    let service_data: any = serviceData.find(
      (item: any) => item.service_id === e.target.value
    );

    setSelectedService({
      service_id: Number(e.target.value),
      service_title: service_data.service_title,
    });

    setFormData({
      ...formData,
      service_required: service_data.service_title,
    });
  };

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SEO
        title="Contact Us"
        description="If you have any questions, need assistance, or want to learn more about our services, feel free to reach out to us."
        keywords="questions, assistance, learn, services"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.famycare.com.au/contact-us"
      />
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="contact-section-pc-container">
            <h3>Contact us</h3>
            <div className="d-flex justify-content-between align-items-start">
              <div className="contact-left">
                <h4>
                  Famycare provide end-to-end support for NDIS participants and
                  can organise support anywhere in Greater Melbourne and
                  Regional Victoria with 48 hours notice.
                </h4>
                <h4>
                  If you have any questions, need assistance, or want to learn
                  more about our services, feel free to reach out to us. We are
                  here to guide you and your family every step of the way and
                  one of our friendly team members will contact you immediately.
                </h4>
              </div>
              <div className="contact-right">
                <form onSubmit={handleContactForm}>
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="fullName"
                    className="reg-input name-field"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    placeholder="Full Name"
                  />
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="phone"
                    className="reg-input name-field"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                  />
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="email"
                    className="reg-input name-field"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <FormGroup>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ width: "100%", marginBottom: "6px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Services Required
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ bgcolor: "#FFFFFF" }}
                        value={selectedService.service_title}
                        onChange={handleServiceChange}
                        label="Services Required"
                      >
                        {serviceData.length > 0 &&
                          serviceData.map((e: any) => (
                            <MenuItem
                              key={e.service_id}
                              value={e.service_id}
                              // onClick={(event) => handleFoodAppChange(e)}
                              disableRipple
                            >
                              {e.service_title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "24px",
                    }}
                    fullWidth
                    required
                    // multiline
                    // rows={4}
                    id="message"
                    className="reg-input name-field"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100"
                    sx={{
                      borderRadius: "12px",
                      bgcolor: "#025D68",
                      padding: "13px 24px",
                      "&:hover": {
                        bgcolor: "#09414E",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="contact-section-mobile-container">
            <h3 className="contact-main-header">Contact us</h3>
            <div className="d-flex flex-column justify-content-between align-items-start">
              <div className="contact-left">
                <h4>
                  Famycare provide end-to-end support for NDIS participants and
                  can organise support anywhere in Greater Melbourne and
                  Regional Victoria with 48 hours notice.
                </h4>
                <h4>
                  If you have any questions, need assistance, or want to learn
                  more about our services, feel free to reach out to us. We are
                  here to guide you and your family every step of the way and
                  one of our friendly team members will contact you immediately.
                </h4>
              </div>
              <div className="contact-right">
                <form onSubmit={handleContactForm}>
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="fullName"
                    className="reg-input name-field"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    placeholder="Full Name"
                  />
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="phone"
                    className="reg-input name-field"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                  />
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "6px",
                    }}
                    fullWidth
                    required
                    id="email"
                    className="reg-input name-field"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <FormGroup>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ width: "100%", marginBottom: "6px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Services Required
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ bgcolor: "#FFFFFF" }}
                        value={selectedService.service_title}
                        onChange={handleServiceChange}
                        label="Services Required"
                      >
                        {serviceData.length > 0 &&
                          serviceData.map((e: any) => (
                            <MenuItem
                              key={e.service_id}
                              value={e.service_id}
                              // onClick={(event) => handleFoodAppChange(e)}
                              disableRipple
                            >
                              {e.service_title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <TextField
                    sx={{
                      width: "100% !important",
                      bgcolor: "#FFFFFF",
                      marginBottom: "24px",
                    }}
                    fullWidth
                    required
                    // multiline
                    // rows={4}
                    id="message"
                    className="reg-input name-field"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100"
                    sx={{
                      borderRadius: "12px",
                      bgcolor: "#025D68",
                      padding: "13px 24px",
                      "&:hover": {
                        bgcolor: "#09414E",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
