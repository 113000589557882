import React from "react";
import Navbar from "../../Navbar";
import AssistSection from "../../Home/AssistSection";
import Footer from "../../Home/Footer";
import "./index.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

function ImportantUpdate() {
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    navigate(e);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#025D68"
      href="/blog"
      sx={{
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: "1rem",
        lineHeight: "110%",
      }}
      onClick={() => handleClick("/blog")}
    >
      Blogs
    </Link>,

    <Typography
      key="3"
      color="text.primary"
      sx={{
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: "1rem",
        lineHeight: "110%",
      }}
    >
      Important update
    </Typography>,
  ];

  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="important-section-pc-container">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ marginBottom: "2.5rem" }}
            >
              {breadcrumbs}
            </Breadcrumbs>

            <h3 className="important-section-header">
              Important update: Changes to NDIS payment processes
            </h3>
            <div className="d-flex align-items-center important-author-details">
              <h4>22nd March, 2024</h4>
              <h4>By Greta Langsford</h4>
            </div>
            <h4 className="important-initial-text">
              We wanted to make sure you are aware of some significant changes
              to NDIS payment terms and processes.
            </h4>

            <h4 className="important-faq-header">What is changing?</h4>
            <h5 className="important-first-answer">
              The NDIA have announced that moving forward they are going to be
              checking all payments more closely which means that invoices will
              take a little longer to be approved and paid.
            </h5>

            <h4 className="important-faq-header">
              What does that mean for payment times?
            </h4>
            <h5>
              After an invoice or reimbursement has been successfully submitted
              to us and approved, we will bill the NDIA.
            </h5>
            <h5>
              NDIA approval is now expected to take two business days. This will
              result in payments typically taking one to two extra days compared
              to current processing times.
            </h5>
            <h5>
              Once approved by the NDIA we’ll pay the following business day.
            </h5>
            <h5 className="important-second-answer">
              In cases where invoices are identified as potentially
              non-compliant, the NDIA will request additional documentation
              which could delay approval times by up to 10 days. This will
              hopefully only impact a small number of invoices.
            </h5>

            <h4 className="important-faq-header">
              For participants: Submitting reimbursements for payment
            </h4>
            <h5>
              When submitting reimbursements, it’s important to remember to
              supply proof of payment to avoid delays in processing and payment
              times.
            </h5>
          </div>
          <div className="important-section-mobile-container">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ marginBottom: "2.5rem" }}
            >
              {breadcrumbs}
            </Breadcrumbs>

            <h3 className="important-section-header">
              Important update: Changes to NDIS payment processes
            </h3>
            <div className="d-flex align-items-center important-author-details">
              <h4>22nd March, 2024</h4>
              <h4>By Greta Langsford</h4>
            </div>
            <h4 className="important-initial-text">
              We wanted to make sure you are aware of some significant changes
              to NDIS payment terms and processes.
            </h4>

            <h4 className="important-faq-header">What is changing?</h4>
            <h5 className="important-first-answer">
              The NDIA have announced that moving forward they are going to be
              checking all payments more closely which means that invoices will
              take a little longer to be approved and paid.
            </h5>

            <h4 className="important-faq-header">
              What does that mean for payment times?
            </h4>
            <h5>
              After an invoice or reimbursement has been successfully submitted
              to us and approved, we will bill the NDIA.
            </h5>
            <h5>
              NDIA approval is now expected to take two business days. This will
              result in payments typically taking one to two extra days compared
              to current processing times.
            </h5>
            <h5>
              Once approved by the NDIA we’ll pay the following business day.
            </h5>
            <h5 className="important-second-answer">
              In cases where invoices are identified as potentially
              non-compliant, the NDIA will request additional documentation
              which could delay approval times by up to 10 days. This will
              hopefully only impact a small number of invoices.
            </h5>

            <h4 className="important-faq-header">
              For participants: Submitting reimbursements for payment
            </h4>
            <h5>
              When submitting reimbursements, it’s important to remember to
              supply proof of payment to avoid delays in processing and payment
              times.
            </h5>
          </div>
        </div>
      </div>
      <AssistSection />
      <Footer />
    </>
  );
}

export default ImportantUpdate;
