import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import DailyLivingAssistanceBanner from "./banner-section";
import DailyLivingAssistanceDetails from "./details-section";
import SEO from "../../SEO";

function DailyLivingAssistance() {
  return (
    <>
      <SEO
        title="Assistance in Daily Living"
        description="Support with essential daily tasks like cooking, cleaning, grooming, and medication management"
        keywords="support, essential, daily, cooking, cleaning, grooming, medication"
        image="../../../assets/images/realistic-scene-with-health-worker-taking-care-elderly-patient 2.png"
        url="https://www.famycare.com.au/assistance-in-daily-living-service"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <DailyLivingAssistanceBanner />
          <DailyLivingAssistanceDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default DailyLivingAssistance;
