import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import CommunityParticipationBanner from "./banner-section";
import CommunityParticipationDetails from "./details-section";
import SEO from "../../SEO";

function CommunityParticipationSection() {
  return (
    <>
      <SEO
        title="Participation in Community"
        description="Opportunities to engage in social, recreational, and community activities, building relationships and confidence"
        keywords="opportunities, social, recreational, community, relationships, confidence"
        image="../../../assets/images/disabled-wheelchair-with-family-park-man-with-his-family-wheelchair 1.png"
        url="https://www.famycare.com.au/participation-in-community-service"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <CommunityParticipationBanner />
          <CommunityParticipationDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CommunityParticipationSection;
