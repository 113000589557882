import "./index.scss";
function DailyLivingAssistanceDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Supporting Your Independence</h1>
        <p>
          Assistance in Daily Living provides essential support for individuals
          with disabilities to help manage everyday activities and foster
          independence. Our services are tailored to your needs in compliance
          with NDIS guidelines, ensuring that you can achieve your daily living
          goals confidently.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>
        <p>
          Assistance in Daily Living provides essential support for individuals
          with disabilities to help manage everyday activities and foster
          independence. Our services are tailored to your needs in compliance
          with NDIS guidelines, ensuring that you can achieve your daily living
          goals confidently.ir unique needs, helping them live more comfortably
          and independently
        </p>
        <h2>1. Meal Preparation and Nutrition:</h2>
        <ul>
          <li>Planning and preparing healthy meals</li>
          <li>Assistance with feeding or mealtime setup</li>
          <li>Grocery shopping and dietary guidance</li>
        </ul>
        <h2>2. Personal Care:</h2>
        <ul>
          <li>Bathing and grooming</li>
          <li>Dressing and undressing</li>
          <li>Toileting and continence management</li>
          <li>Medication management</li>
        </ul>
        <h2>3. Domestic Assistance:</h2>
        <ul>
          <li>Cleaning and laundry</li>
          <li>Organizing and tidying living spaces</li>
          <li>Changing bed linens and general household upkeep</li>
        </ul>

        <h2>4. Health and Safety:</h2>
        <ul>
          <li>Monitoring and managing medical conditions</li>
          <li>Fall prevention and mobility assistance</li>
          <li>Assistance with assistive devices or medical equipment</li>
        </ul>

        <h2>5. Participation in Community:</h2>
        <ul>
          <li>Support for attending medical appointments</li>
          <li>Help with running errands and transportation</li>
          <li>
            Assistance with joining social, recreational, or community
            activities
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DailyLivingAssistanceDetails;
