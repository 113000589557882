import "./index.scss";
function NursingCareDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Nursing and Complex Care - Comprehensive Medical Support</h1>
        <p>
          Nursing and Complex Care services provide specialized medical
          assistance for individuals with disabilities who have intricate health
          needs. These services are delivered by registered nurses and
          healthcare professionals in adherence to NDIS guidelines, ensuring
          participants' safety, well-being, and quality of life.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>
        <p>
          These services ensure that participants receive holistic medical care
          that meets their unique needs, helping them live more comfortably and
          independently
        </p>
        <h2>1. Complex Wound Management and Care:</h2>
        <ul>
          <li>
            Cleaning, dressing, and monitoring of wounds or surgical sites.
          </li>
          <li>Creating care plans for managing chronic wounds.</li>
        </ul>
        <h2>2. Medication Management</h2>
        <ul>
          <li>
            Administration and monitoring of oral, injectable, and infusion
            medications.
          </li>
          <li>
            Assistance with reminders and education on proper medication use.
          </li>
        </ul>
        <h2>3. Urinary Catheter Management:</h2>
        <ul>
          <li>Placement, maintenance and replacement of urinary catheters.</li>
        </ul>

        <h2>4. Pain Management:</h2>
        <ul>
          <li>
            Developing and implementing personalized pain management plans.
          </li>
          <li>Monitoring for pain relief effectiveness.</li>
          <li>Assistance with assistive devices or medical equipment</li>
        </ul>

        <h2>5. Complex Bowel Management:</h2>
        <ul>
          <li>
            Supporting effective bowel care routines and managing constipation.
          </li>
        </ul>
        <h2>6. Tracheostomy Management:</h2>
        <ul>
          <li>
            Cleaning and care for tracheostomy tubes, ensuring a clear airway.
          </li>
          <li>
            Educating participants and caregivers on proper tracheostomy care.
          </li>
        </ul>
        <h2>7. Parenteral Feed Management:</h2>
        <ul>
          <li>
            Management of intravenous or tube feeding for individuals with
            nutritional needs.
          </li>
        </ul>
        <h2>8. Stoma Management:</h2>
        <ul>
          <li>
            Assistance with care routines for colostomies, ileostomies, and
            urostomies.
          </li>
          <li>Educating participants on best stoma care practices.</li>
        </ul>
        <h2>9. Diabetes Management:</h2>
        <ul>
          <li>
            Monitoring blood glucose levels, medication administration, and
            lifestyle advice.
          </li>
        </ul>
        <h2>10. Dementia and Alzheimer’s Care:</h2>
        <ul>
          <li>
            Providing specialized support for those affected by dementia or
            Alzheimer's disease.
          </li>
          <li>
            Creating care plans that promote mental stimulation and emotional
            well-being.
          </li>
        </ul>
        <h2>11. Palliative and End-of-Life Care:</h2>
        <ul>
          <li>
            Delivering compassionate care for individuals requiring palliative
            support.
          </li>
          <li>
            Providing emotional assistance to the patient and their families.
          </li>
        </ul>
        <h2>12. Ventilator Management:</h2>
        <ul>
          <li>
            Assistance with operating and maintaining ventilators for
            individuals needing respiratory support.
          </li>
        </ul>
        <h2>13. Seizure Management:</h2>
        <ul>
          <li>
            Monitoring and managing seizure activity to ensure immediate and
            long-term care.
          </li>
        </ul>
        <h2>14. Support with Continence Needs:</h2>
        <ul>
          <li>
            Assisting with continence products and bladder/bowel care routines.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NursingCareDetails;
