import React from "react";
import EmpathyIcon from "../../../assets/images/empathy_icon.svg";
import TrustIcon from "../../../assets/images/trust_icon.svg";
import EmpowermentIcon from "../../../assets/images/empowerment_icon.svg";
import FairnessIcon from "../../../assets/images/fairness_icon.svg";
import CollaborationIcon from "../../../assets/images/collaboration_icon.svg";
import InnovationIcon from "../../../assets/images/innovation_icon.svg";
import Card from "@mui/material/Card";
import "./index.scss";
import { CardContent } from "@mui/material";

const reasons = [
  {
    reason_id: 0,
    reason_title: "Collaboration and Support",
    reason_content:
      "We work closely with clients, families, and support networks to create a strong community of care.",
    reason_img: CollaborationIcon,
  },
  {
    reason_id: 1,
    reason_title: "Empathy and Understanding",
    reason_content:
      "We listen and respond to the unique needs of each client with compassion and understanding.",
    reason_img: EmpathyIcon,
  },
  {
    reason_id: 2,
    reason_title: "Fairness and Inclusion",
    reason_content:
      "We ensure every individual receives equal access to opportunities and care, regardless of their background.",
    reason_img: FairnessIcon,
  },
  {
    reason_id: 3,
    reason_title: "Individual Empowerment",
    reason_content:
      "We empower clients to make their own choices and live independently by offering personalized services.",
    reason_img: EmpowermentIcon,
  },
  {
    reason_id: 4,
    reason_title: "Innovation and Growth",
    reason_content:
      "We are constantly improving our services through innovative thinking and encouraging personal growth for both clients and staff.",
    reason_img: InnovationIcon,
  },
  {
    reason_id: 5,
    reason_title: "Trust and Reliability",
    reason_content:
      "You can count on us to deliver dependable support, always upholding the highest standards.",
    reason_img: TrustIcon,
  },
];

function WhySection() {
  return (
    <>
      <div className="why-section-pc-container">
        <div className="why-main-header">
          <h3>Why us</h3>
        </div>
        <Card
          className="d-flex justify-content-center align-items-center flex-wrap"
          style={{
            gap: "40px 48px",
            border: "1px solid #2B2D42",
            borderRadius: "40px",
            padding: "69px 35px 47px 36px",
            backgroundColor: "transparent",
          }}
        >
          {reasons.map((e: any) => {
            return (
              <div className="why-section-cards">
                <div className="d-flex align-items-center why-card-header">
                  <img src={e.reason_img} alt="" />
                  <h5>{e.reason_title} </h5>
                </div>
                <h6>{e.reason_content}</h6>
              </div>
            );
          })}
        </Card>
      </div>
      <div className="why-section-mobile-container">
        <div className="why-main-header">
          <h3>Why us</h3>
        </div>
        <Card
          className="d-flex flex-column justify-content-center align-items-center flex-wrap"
          style={{
            gap: "40px 48px",
            border: "1px solid #2B2D42",
            borderRadius: "10px",
            padding: "69px 35px 47px 36px",
            backgroundColor: "transparent",
          }}
        >
          {reasons.map((e: any) => {
            return (
              <div className="why-section-cards">
                <div className="d-flex align-items-center why-card-header">
                  <img src={e.reason_img} alt="" />
                  <h5>{e.reason_title} </h5>
                </div>
                <h6>{e.reason_content}</h6>
              </div>
            );
          })}
        </Card>
      </div>
    </>
  );
}

export default WhySection;
