import "./index.scss";
import React from "react";
import NursingImg from "../../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import NursingMobileImg from "../../../../assets/images/nurse-care-mobile.png";
import "./index.scss";

function NursingCareBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Nursing & Complex Care</h1>

          <h2>
            Comprehensive healthcare support, including wound care, medication
            management, and medical equipment assistance.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={NursingImg} alt="" className="desktop_image" />
          <img src={NursingMobileImg} alt="" className="mobile_image" />
        </div>
      </div>
    </div>
  );
}

export default NursingCareBanner;
