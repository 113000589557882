import "./index.scss";
function CommunityParticipationDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Enhancing Life Through Active Participation</h1>
        <p>
          Connection is crucial for enhancing quality of life. Famycare is
          dedicated to facilitating community engagement, providing
          opportunities for individuals with disabilities to participate
          meaningfully in local events. This active involvement not only boosts
          wellness but also promotes the building of lasting friendships and
          supportive networks.
        </p>
        <p>
          Understanding the unique challenges faced by individuals with
          disabilities, Famycare ensures that all activities are accessible,
          removing barriers that might hinder participation. A variety of
          community events and activities are made safe and enjoyable, supported
          by tailored assistance.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>Types of Activities:</h1>
        <p>
          These services ensure that participants receive holistic medical care
          that meets their unique needs, helping them live more comfortably and
          independently
        </p>
        <h2>1. Sports and Recreation</h2>
        <ul>
          <li>
            Adaptive sports and recreational groups are available, offering
            enjoyable and active engagement for all abilities.
          </li>
        </ul>
        <h2>2. Social Connections</h2>
        <ul>
          <li>
            Opportunities to meet new people or join social gatherings through
            clubs that align with diverse interests.
          </li>
        </ul>
        <h2>3. Cultural and Educational Pursuits</h2>
        <ul>
          <li>
            Accessible resources like local libraries and workshops, along with
            classes for starting new hobbies, are designed to accommodate
            various learning styles and paces.
          </li>
        </ul>

        <h2>4. Cultural and Educational Pursuits</h2>
        <ul>
          <li>
            Volunteer opportunities that welcome and value the contributions of
            all community members.
          </li>
        </ul>

        <h2>5. Leisure and Entertainment</h2>
        <ul>
          <li>
            Accessible outings including movie screenings and theater
            performances ensure entertainment is enjoyable for everyone.
          </li>
        </ul>

        <h2>6. Structured Programs</h2>
        <ul>
          <li>
            Specially organized activities such as camps and holiday-themed
            events provide fun, education, and community interaction.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CommunityParticipationDetails;
