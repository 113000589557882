import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownIcon from "./DropdownIcon";
import { useState } from "react";
import LeftDrawer from "../Sidebar/Sidebar";

interface Props {
  name?: string;
  imagePath?: string;
  color?: string;
  className?: string;
  buttonClass?: string;
  endIcon?: any;
  spanclass?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  customIcon?: React.AllHTMLAttributes<HTMLDivElement>;
  customText?: string;
  customFunction?: any;
  menuItemClass?: string;
  menuDataItemClass?: string;
  dropDownData?: any;
  customElement?: any;
  handleFieldClick?: any;
  menuClass?: string;
  menuData?: any;
  profileFunction?: any;
  scrollToSection?: any;
  homeRef?: any;
  aboutRef?: any;
  contactRef?: any;
  careerRef?: any;
  blogRef?: any;
  accomodationRef?: any;
  communityRef?: any;
  dailyRef?: any;
  hospitalRef?: any;
  individualRef?: any;
  lifeRef?: any;
  nursingRef?: any;
  supportedRef?: any;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Dropdown(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <div className={"new"} onClick={props.onClick}>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          // onClick={handleClick}
          endIcon={props.endIcon}
          style={{ backgroundColor: props.color }}
          className={props.buttonClass}
          sx={{ minWidth: "24px" }}
          onClick={toggleDrawer}
        >
          {props.imagePath && (
            <img
              src={props.imagePath}
              style={{ width: "24px", height: "24px" }}
              alt=""
            />
          )}
          {/* <span className={props.spanclass}>{props.name}</span> */}
        </Button>
        <Menu
          disableScrollLock
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={props.menuClass}
        >
          {props.menuData &&
            props.menuData.length > 0 &&
            props.menuData.map((item: any, index: number) => {
              return (
                <MenuItem
                  key={index}
                  className={props.menuDataItemClass}
                  value={item.value ? item.value : ""}
                  onClick={(e) => {
                    item.value === "Logout" && props.customFunction();
                    item.value === "Profile" && props.profileFunction();
                    props.handleFieldClick(e);
                    props.scrollToSection(item.href);
                    handleClose();
                  }}
                  disableRipple
                >
                  {props.customElement && props.customElement}
                  {item.value ? item.value : ""}
                </MenuItem>
              );
            })}
        </Menu>
      </div>
      <LeftDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        menuItems={props.menuData}
        scrollToSection={props.scrollToSection}
        homeRef={props.homeRef}
        aboutRef={props.aboutRef}
        contactRef={props.contactRef}
        careerRef={props.careerRef}
        blogRef={props.blogRef}
        accomodationRef={props.accomodationRef}
        communityRef={props.communityRef}
        dailyRef={props.dailyRef}
        hospitalRef={props.hospitalRef}
        individualRef={props.individualRef}
        lifeRef={props.lifeRef}
        nursingRef={props.nursingRef}
        supportedRef={props.supportedRef}
      />
    </>
  );
}
