import React from "react";
import DailyLivingImg from "../../../../assets/images/realistic-scene-with-health-worker-taking-care-elderly-patient 2.png";
import DailyLivingMobileImg from "../../../../assets/images/realistic-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function DailyLivingAssistanceBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Assistance in Daily Living</h1>

          <h2>
            Support with essential daily tasks like cooking, cleaning, grooming,
            and medication management
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img src={DailyLivingImg} alt="" className="desktop_image" />
          <img src={DailyLivingMobileImg} alt="" className="mobile_image" />
        </div>
      </div>
    </div>
  );
}

export default DailyLivingAssistanceBanner;
