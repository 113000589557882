import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import NursingCareBanner from "./banner-section";
import NursingCareDetails from "./details-section";
import SEO from "../../SEO";

function NursingCare() {
  return (
    <>
      <SEO
        title="Nursing & Complex Care"
        description="Comprehensive healthcare support, including wound care, medication management, and medical equipment assistance."
        keywords="comprehensive, healthcare, medication, management, equipment, assistance"
        image="../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png"
        url="https://www.famycare.com.au/nursing-care"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <NursingCareBanner />
          <NursingCareDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NursingCare;
