import React, { useRef } from "react";
import Navbar from "../Navbar";
import HeroSection from "./HeroSection";
import MissionSection from "./MissionSection";
import WhySection from "./WhySection";
import "./index.scss";
import ServicesSection from "./ServicesSection";
import AssistSection from "./AssistSection";
import Footer from "./Footer";
import ChartSection from "./ChartSection";
import SEO from "../SEO";
import image from "../../assets/images/hero_section_bg.png";

function Home() {
  const heroRef = useRef(null);

  const scrollToSection = (sectionRef: any) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <SEO
        title="Home Page - My Website"
        description="Leading Support for Brighter Futures"
        keywords="lead, support, brighter futures"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.famycare.com.au/"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <div ref={heroRef} id="hero">
            <HeroSection />
          </div>
          <MissionSection />
          <ChartSection />
          <WhySection />
          <ServicesSection />
          <AssistSection />
          <Footer scrollToSection={scrollToSection} heroRef={heroRef} />
        </div>
      </div>
    </>
  );
}

export default Home;
