import React, { useState } from "react";
import FamyCareLogo from "../../assets/images/famycare_logo.svg";
import MessageIcon from "../../assets/images/message_icon.svg";
import CallIcon from "../../assets/images/call_icon.svg";
import Button from "@mui/material/Button";
import "./index.scss";
import NavbarMobileIcon from "../../assets/images/navbar_mobile_icon.svg";
import AccomodationImg from "../../assets/images/accomodation_img.png";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Typography } from "@mui/material";
import AccomodationIconImg from "../../assets/images/icons/accomodation_icon_img.svg";
import NursingIconImg from "../../assets/images/icons/nursing_icon_img.svg";
import DailyLifeIconImg from "../../assets/images/icons/dailylife_icon_img.svg";
import SupportedIconImg from "../../assets/images/icons/supported_icon_img.svg";
import IndividualisedIconImg from "../../assets/images/icons/individual_icon_img.svg";
import HospitalIconImg from "../../assets/images/icons/hospital_icon_img.svg";
import CommunityIconImg from "../../assets/images/icons/community_icon_img.svg";
import LifeSkillsIconImg from "../../assets/images/icons/lifeskills_icon_img.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HospitalImg from "../../assets/images/hospital_img.png";
import NursingImg from "../../assets/images/nursing_img.png";
import DailyImg from "../../assets/images/daily_img.png";
import LifeImg from "../../assets/images/life_img.png";
import IndividualImg from "../../assets/images/individual_img.png";
import CommunityImg from "../../assets/images/community_img.png";
import SupportedImg from "../../assets/images/support_img.png";
import Dropdown from "../Dropdown/Dropdown";

const navbarItems = [
  {
    item_id: 1,
    item_title: "Home",
    item_link: "/home",
  },
  {
    item_id: 2,
    item_title: "About us",
    item_link: "/about-us",
  },
  {
    item_id: 3,
    item_title: "Services",
    item_link: "/our-services",
  },
  {
    item_id: 4,
    item_title: "Referral",
    item_link: "/referral",
  },
  {
    item_id: 5,
    item_title: "Career",
    item_link: "/career",
  },
  {
    item_id: 6,
    item_title: "Blog",
    item_link: "/blog",
  },
  {
    item_id: 7,
    item_title: "Contact us",
    item_link: "/contact-us",
  },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

const services = [
  {
    service_id: 0,
    service_img: AccomodationImg,
    service_icon_img: AccomodationIconImg,
    service_title: "Accomodation Support",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/accomodation-support",
  },
  {
    service_id: 1,
    service_img: DailyImg,
    service_icon_img: DailyLifeIconImg,
    service_title: "Assistance in Daily Living",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/assistance-in-daily-living-service",
  },
  {
    service_id: 2,
    service_img: LifeImg,
    service_icon_img: LifeSkillsIconImg,
    service_title: "Development of Life Skills",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/development-of-life-skills-service",
  },
  {
    service_id: 3,
    service_img: HospitalImg,
    service_icon_img: HospitalIconImg,
    service_title: "Hospital to Home Services",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/hospital-to-home-service",
  },
  {
    service_id: 4,
    service_img: IndividualImg,
    service_icon_img: IndividualisedIconImg,
    service_title: "Individualised Living Options (ILO)",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/individualised-living-service",
  },

  {
    service_id: 5,
    service_img: NursingImg,
    service_icon_img: NursingIconImg,
    service_title: "Nursing & Complex Care",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/nursing-care",
  },
  {
    service_id: 6,
    service_img: CommunityImg,
    service_icon_img: CommunityIconImg,
    service_title: "Participation in Community",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/participation-in-community-service",
  },
  {
    service_id: 7,
    service_img: SupportedImg,
    service_icon_img: SupportedIconImg,
    service_title: "Supported Independent Living",
    service_content:
      "Guidance in finding suitable housing and managing accommodation needs with ongoing support.",
    service_link: "/supported-independent-living-service",
  },
];

const profileData = [
  {
    key: "Services",
    value: "Services",
    href: "#services",
    // route: "",
  },
  {
    key: "About",
    value: "About us",
    href: "#about",
    route: "/about-us",
  },
  {
    key: "Contact",
    value: "Contact us",
    href: "#contact",
    route: "/contact-us",
  },
  {
    key: "Career",
    value: "Career",
    href: "#career",
    route: "/career",
  },
  {
    key: "Referral",
    value: "Referral",
    href: "#referral",
    route: "/referral",
  },
  {
    key: "Blog",
    value: "Blog",
    href: "#blog",
    route: "/blog",
  },
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "1.5rem 5rem 2.5rem 5.1875rem",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Navbar(props: any) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<any>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (e: any) => {
    navigate(e);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  const handlePdf = () => {
    window.open("/famycare_referral_pdf.pdf", "_blank");
  };

  const handleMail = () => {
    window.location.href = "mailto:info@famycare.com.au";
  };

  const handleCall = () => {
    window.location.href = "tel:+61475977457";
  };

  return (
    <>
      <div className="navbar-pc-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center logo-section">
            <img src={FamyCareLogo} alt="" />
            <h6>Famycare</h6>
          </div>
          <div>
            <Button
              variant="contained"
              className="me-4 message-btn"
              onClick={handleMail}
            >
              <div className="d-flex align-items-center">
                <img src={MessageIcon} style={{ marginRight: "10px" }} alt="" />
                <h4 className="button-text">Email Us</h4>
              </div>
            </Button>
            <Button
              variant="contained"
              className="call-btn"
              onClick={handleCall}
            >
              <div className="d-flex align-items-center">
                <img src={CallIcon} style={{ marginRight: "10px" }} alt="" />
                <h4 className="button-text">Call Now</h4>
              </div>
            </Button>
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ gap: "40px", padding: "31px 387.5px 0 220.5px" }}
        >
          {navbarItems.map((e: any) => {
            return e.item_link === "/our-services" ? (
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  color: "#025D68",
                  lineHeight: "110%",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#025D68",
                    textUnderlineOffset: "8px",
                    textDecorationThickness: "2px",
                  },
                }}
                className="dropdown-menu"
              >
                <span style={{ textTransform: "capitalize" }}>
                  {e.item_title}
                </span>
              </Button>
            ) : e.item_link === "/referral" ? (
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handlePdf}
                sx={{
                  color: "#025D68",
                  lineHeight: "110%",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#025D68",
                    textUnderlineOffset: "8px",
                    textDecorationThickness: "2px",
                  },
                }}
                className="dropdown-menu"
              >
                <span style={{ textTransform: "capitalize" }}>
                  {e.item_title}
                </span>
              </Button>
            ) : e.item_link === "/about-us" || e.item_link === "/contact-us" ? (
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={() => handleNavigate(e.item_link)}
                sx={{
                  color: "#025D68",
                  lineHeight: "110%",
                  textTransform: "none !important",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#025D68",
                    textUnderlineOffset: "8px",
                    textDecorationThickness: "2px",
                    textTransform: "none",
                  },
                }}
                className="dropdown-menu"
              >
                <span>{e.item_title}</span>
              </Button>
            ) : (
              <Button
                sx={{
                  color: "#025D68",
                  lineHeight: "110%",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#025D68",
                    textUnderlineOffset: "8px",
                    textDecorationThickness: "2px",
                  },
                }}
                className="dropdown-menu"
                onClick={() => handleNavigate(e.item_link)}
              >
                <span style={{ textTransform: "capitalize" }}>
                  {e.item_title}
                </span>
              </Button>
            );
          })}
        </div>
      </div>
      <div className="navbar-mobile-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center logo-section">
            <img src={FamyCareLogo} alt="" />
            <h6>Famycare</h6>
          </div>
        </div>
        {/* <Button className="navbar-mobile-dropdown">
          <img src={NavbarMobileIcon} alt="" />
        </Button> */}
        <Dropdown
          // name={state?.user?.first_name}
          // name={props.user.first_name}
          menuData={profileData}
          imagePath={NavbarMobileIcon}
          color="transparent"
          className="pro-btn-main"
          buttonClass="pro-nav-btn pro-acc-btn navbar-mobile-btn"
          menuClass="menu-navbar"
          // endIcon={<DropdownIcon />}
          // endIcon={<DropdownIcon iconColor="white" />}
          spanclass={"span-spacing"}
          // customIcon={<LogoutIcon />}
          customText="Logout"
          // customFunction={handleLogout}
          // profileFunction={handleProfile}
          handleFieldClick={handleFieldClick}
          // scrollToSection={props.scrollToSection}
          // homeRef={props.homeRef}
          // storyRef={props.storyRef}
          // malayaliRef={props.malayaliRef}
          // newsRef={props.newsRef}
          // contactRef={props.contactRef}
          // productsRef={props.productsRef}
        />
      </div>
      <div>
        {/* <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Options
        </Button> */}
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Typography sx={{ marginBottom: "1.5rem" }}>Our Services</Typography>
          <div
            className="grid-ish"
            // style={{
            //   gap: "15%",
            //   flexWrap: "wrap",
            // }}
          >
            {services.map((e: any) => {
              return (
                <MenuItem
                  onClick={() => navigate(e.service_link)}
                  disableRipple
                  sx={{
                    // padding: 0,
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    minHeight: "30px !important",
                    marginBottom: "1rem",
                    minWidth: "9.375rem",
                  }}
                >
                  <img src={e.service_icon_img} alt="" className="me-2" />
                  {e.service_title}
                </MenuItem>
              );
            })}
          </div>
        </StyledMenu>
        {renderMobileMenu}
      </div>
    </>
  );
}

export default Navbar;
