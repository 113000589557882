import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import CommunityParticipationBanner from "./banner-section";
import LifeSkillsDevelopmentBanner from "./banner-section";
import IndividualisedLivingBanner from "./banner-section";
import IndividualisedLivingDetails from "./details-section";
import SEO from "../../SEO";

function IndividualisedLiving() {
  return (
    <>
      <SEO
        title="Individualised Living Options (ILO)"
        description="Customized support arrangements that align with your lifestyle and help you live comfortably and independently."
        keywords="support, arrangements, lifestyle, live, comfortably, independently"
        image="../../../assets/images/pexels-cliff-booth-4058218 1.png"
        url="https://www.famycare.com.au/individualised-living-service"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <IndividualisedLivingBanner />
          <IndividualisedLivingDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default IndividualisedLiving;
