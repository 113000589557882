import React from "react";
import SupportedIndependentLivingImg from "../../../../assets/images/realistic-scene-with-elderly-care-senior-people 2.png";
import SupportedIndependentLivingMobileImg from "../../../../assets/images/realistic-scene-mobile.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../../assets/images/message_icon.svg";
import CallIcon from "../../../../assets/images/call_icon.svg";

function SupportedIndependentLivingBanner() {
  return (
    <div className="container-flex accommodation_section_banner">
      <div className="row banner_row">
        <div className="col-lg-6 col-sm-12 banner_col">
          <h1>Supported Independent Living (SIL):</h1>

          <h2>
            Tailored support for daily activities and personal care, promoting
            independent living in shared or individual homes.
          </h2>
        </div>
        <div className="col-lg-6 col-sm-12 banner__image_col">
          <img
            src={SupportedIndependentLivingImg}
            alt=""
            className="desktop_image"
          />
          <img
            src={SupportedIndependentLivingMobileImg}
            alt=""
            className="mobile_image"
          />
        </div>
      </div>
    </div>
  );
}

export default SupportedIndependentLivingBanner;
