import React from "react";
import FamyCareLogo from "../../../assets/images/famycare_logo.svg";
import LinkedInIcon from "../../../assets/images/linkedin_icon.svg";
import FacebookIcon from "../../../assets/images/facebook_icon.svg";
import TwitterIcon from "../../../assets/images/twitter_icon.svg";
import InstagramIcon from "../../../assets/images/instagram_icon.svg";
import CallIcon from "../../../assets/images/phone_icon_footer.svg";
import MessageIcon from "../../../assets/images/mail_icon_footer.svg";
import "./index.scss";
import Button from "@mui/material/Button";
import FlagImg from "../../../assets/images/flag_img.png";
import GoupButtonIcon from "../../../assets/images/go_up_btn.svg";
import FamyCareFooterLogo from "../../../assets/images/famycare_footer_logo.svg";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Footer(props: any) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleMail = () => {
    window.location.href = "mailto:info@famycare.com.au";
  };

  const handleCall = () => {
    window.location.href = "tel:++61475977457";
  };

  const handleLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/in/famy-care-66577b311?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      "_blank"
    );
  };

  const handleFacebook = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61559517455814&mibextid=kFxxJD",
      "_blank"
    );
  };

  const handleInstagram = () => {
    window.open(
      "https://www.instagram.com/famycares?igsh=MWRwbDcyajVpMTZlOA==",
      "_blank"
    );
  };

  const handleTwitter = () => {
    window.open("https://x.com/famycare87492", "_blank");
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="footer-section-pc-container">
          <div className="d-flex justify-content-center align-items-center footer-first-section">
            <div className="footer-first-left">
              <div className="d-flex align-items-center logo-section logo-footer">
                <img src={FamyCareLogo} alt="" />
                <h6 className="footer-first-left-header">Famycare</h6>
              </div>
              <h5 className="footer-first-left-subtext">
                Bringing Confidence and Care to Your Journey.
              </h5>
              <h6>Follow us on</h6>
              <div
                className="d-flex align-items-center"
                style={{ gap: "1.3456rem" }}
              >
                <Button onClick={handleLinkedIn}>
                  <img src={LinkedInIcon} alt="" />
                </Button>
                <Button onClick={handleFacebook}>
                  <img src={FacebookIcon} alt="" />
                </Button>
                <Button onClick={handleTwitter}>
                  <img src={TwitterIcon} alt="" />
                </Button>
                <Button onClick={handleInstagram}>
                  <img src={InstagramIcon} alt="" />
                </Button>
              </div>
            </div>
            <div className="footer-first-middle d-flex justify-content-between align-items-start">
              <div>
                <h4 className="footer-middle-subheader">Our services</h4>
                <Typography
                  component={Link}
                  to={"/accomodation-support"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Accomodation Support
                </Typography>
                <Typography
                  component={Link}
                  to={"/assistance-in-daily-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Assistance in Daily Living
                </Typography>
                <Typography
                  component={Link}
                  to={"/development-of-life-skills-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Development of Life Skills
                </Typography>

                <Typography
                  component={Link}
                  to={"/hospital-to-home-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Hospital to home Service
                </Typography>
                <Typography
                  component={Link}
                  to={"/individualised-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Individualised Living Options
                </Typography>
                <Typography
                  component={Link}
                  to={"/nursing-care"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Nursing & Complex Care
                </Typography>
                <Typography
                  component={Link}
                  to={"/participation-in-community-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Participation in Community
                </Typography>
                <Typography
                  component={Link}
                  to={"/supported-independent-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Supported Independent Living
                </Typography>
              </div>
              <div>
                <h4 className="footer-middle-subheader">Other Links</h4>
                <Typography
                  component={Link}
                  to={"/blog"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  <h4>Blogs</h4>
                </Typography>
                <Typography
                  component={Link}
                  to={"/career"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  <h4>Careers</h4>
                </Typography>
              </div>
            </div>
            <div className="footer-first-right">
              <div>
                <h4 className="footer-right-subheader">Contact us</h4>
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={handleCall}
                >
                  <img src={CallIcon} alt="" />
                  <h4>+61 475 977 457</h4>
                </div>
                <div
                  className="d-flex align-items-center"
                  onClick={handleMail}
                  style={{ cursor: "pointer" }}
                >
                  <img src={MessageIcon} alt="" />
                  <h4>info@famycare.com.au </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-second-section">
            <div className="text-center">
              <h4>Reconciliation Statement</h4>
              <div className="d-flex justify-content-center align-items-center">
                <h5 style={{ width: "29.125rem" }}>
                  Famycare acknowledge Traditional Owners and Custodians of the
                  land on which we live, and pay respect to their elders, past,
                  present and emerging. We extend our respects to all Aboriginal
                  and Torres Strait Islander peoples.
                </h5>
              </div>
              <div>
                <img src={FlagImg} alt="" />
              </div>
            </div>
          </div>
          <div className="footer-third-section">
            <h4>Copyright © 2024 Famycare. All rights reserved.</h4>
            <Button className="go-up-btn">
              <img src={GoupButtonIcon} alt="" />
            </Button>
          </div>
        </div>
        <div className="footer-section-mobile-container">
          <div className="d-flex flex-column justify-content-center align-items-center footer-first-section">
            <div className="footer-first-left">
              <div className="d-flex align-items-center logo-section logo-footer">
                <img src={FamyCareLogo} alt="" />
                <h6>Famycare</h6>
              </div>
              <h5>Bringing Confidence and Care to Your Journey.</h5>
              <h6>Follow us on</h6>
              <div
                className="d-flex align-items-center"
                style={{ gap: "1.3456rem" }}
              >
                <Button onClick={handleLinkedIn}>
                  <img src={LinkedInIcon} alt="" />
                </Button>
                <Button onClick={handleFacebook}>
                  <img src={FacebookIcon} alt="" />
                </Button>
                <Button onClick={handleTwitter}>
                  <img src={TwitterIcon} alt="" />
                </Button>
                <Button onClick={handleInstagram}>
                  <img src={InstagramIcon} alt="" />
                </Button>
              </div>
            </div>
            <div
              className="footer-first-middle d-flex flex-column justify-content-between align-items-start"
              style={{ gap: "2.5rem" }}
            >
              <div>
                <h4 className="footer-middle-subheader">Our services</h4>
                <Typography
                  component={Link}
                  to={"/accomodation-support"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Accomodation Support
                </Typography>
                <Typography
                  component={Link}
                  to={"/assistance-in-daily-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Assistance in Daily Living
                </Typography>
                <Typography
                  component={Link}
                  to={"/development-of-life-skills-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Development of Life Skills
                </Typography>
                <Typography
                  component={Link}
                  to={"/hospital-to-home-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Hospital to home <br /> Service
                </Typography>
                <Typography
                  component={Link}
                  to={"/individualised-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Individualised Living Options
                </Typography>
                <Typography
                  component={Link}
                  to={"/nursing-care"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Nursing & Complex Care
                </Typography>
                <Typography
                  component={Link}
                  to={"/participation-in-community-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Participation in Community
                </Typography>
                <Typography
                  component={Link}
                  to={"/supported-independent-living-service"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  Supported Independent Living
                </Typography>
              </div>
              <div>
                <h4 className="footer-middle-subheader">Other Links</h4>
                <Typography
                  component={Link}
                  to={"/blog"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  <h4>Blogs</h4>
                </Typography>
                <Typography
                  component={Link}
                  to={"/career"}
                  variant="h4"
                  onClick={scrollToTop}
                >
                  <h4>Careers</h4>
                </Typography>
              </div>
            </div>
            <div className="footer-first-right">
              <div>
                <h4 className="footer-right-subheader">Contact us</h4>
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={handleCall}
                >
                  <img src={CallIcon} alt="" />
                  <h4>+61 475 977 457</h4>
                </div>
                <div
                  className="d-flex align-items-center"
                  onClick={handleMail}
                  style={{ cursor: "pointer" }}
                >
                  <img src={MessageIcon} alt="" />
                  <h4>info@famycare.com.au </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-second-section">
            <div className="text-left">
              <h4>Reconciliation Statement</h4>
              <h5>
                Famycare acknowledge Traditional Owners and Custodians of the
                land on which we live, and pay respect to their elders, past,
                present and emerging. We extend our respects to all Aboriginal
                and Torres Strait Islander peoples.
              </h5>
              <div>
                <img src={FlagImg} alt="" />
              </div>
            </div>
          </div>
          <div className="footer-third-section">
            <h4>Copyright © 2024 Famycare. All rights reserved.</h4>
            <Button
              className="go-up-btn"
              onClick={() => props.scrollToSection(props.heroRef)}
            >
              <img src={GoupButtonIcon} alt="" />
            </Button>
          </div>
          <div className="footer-final-cta">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={FamyCareFooterLogo}
                style={{ marginRight: "0.5625rem" }}
                alt=""
              />
              <h4>Contact us Now</h4>
            </div>
            <Button
              variant="contained"
              className="footer-call-btn"
              onClick={handleCall}
            >
              Call Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
