import "./index.scss";
function AccommodationDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Finding Your Ideal Home with Expert Support</h1>
        <p>
          At Famycare, we collaborate closely with the NDIS to provide tailored
          housing assistance that fits your unique needs. We understand the
          importance of finding a place to call home where you can develop the
          skills and routines necessary for more independent living.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>Types of Accommodations Provided</h1>
        <p>
          These services ensure that participants receive holistic medical care
          that meets their unique needs, helping them live more comfortably and
          independently
        </p>
        <h2>1. Short-Term Accommodation</h2>
        <ul>
          <li>
            Ideal for learning new skills towards greater independence. This
            option also focuses on social development for children and provides
            a fun environment. It's perfect for giving families and carers a
            temporary respite from their caring responsibilities.
          </li>
        </ul>
        <h2>2. Medium-Term Accommodation</h2>
        <ul>
          <li>
            This accommodation is suitable for stays up to 90 days, ideal for
            those awaiting home modifications, recovering from hospital stays,
            or requiring other disability supports. It offers a chance to
            establish a daily routine, form friendships, and develop lifestyle
            skills in a secure setting.
          </li>
        </ul>
        <h2>3. Private Rental Assistance:</h2>
        <ul>
          <li>
            We can assist in finding private rental options, working with you to
            identify and secure more permanent housing solutions that meet your
            long-term needs.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AccommodationDetails;
