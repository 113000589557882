import React from "react";
import Navbar from "../Navbar";
import AssistSection from "../Home/AssistSection";
import Footer from "../Home/Footer";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import SEO from "../SEO";

function Blog() {
  const navigate = useNavigate();
  const handleNavigate = (e: any) => {
    if (e === "NDIS") {
      navigate("/blog/NDIS-pricing-changes");
    } else {
      navigate("/blog/Important-update");
    }
  };

  const truncateText = (text: any, charLimit: any) => {
    if (text.length <= charLimit) {
      return text;
    }
    return text.substring(0, charLimit) + "...";
  };

  return (
    <>
      <SEO
        title="Blogs"
        description="Insights and Tips for Your Family’s Health and Well-being"
        keywords="insights, tips, health, well-being"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.famycare.com.au/blog"
      />
      <Navbar />
      <div className="row">
        <div className="col-xs-12">
          <div className="blog-section-pc-container">
            <h3 className="blog-main-header">Blogs</h3>
            <h4 className="blog-main-subtext">
              Insights and Tips for Your Family’s Health and Well-being
            </h4>
            <h3 className="blog-second-header">News</h3>
            <div className="d-flex align-items-center blog-news-cards">
              <Card>
                <h4>22nd March, 2024</h4>
                <h5>
                  <strong>
                    Important update: Changes to NDIS payment processes
                  </strong>
                </h5>
                <h4>
                  The NDIA have announced that moving forward they are going to
                  be checking all payments more closely which means that
                  invoices will take a little longer to be approved and paid.
                </h4>
                <Button onClick={() => handleNavigate("Important")}>
                  Learn More
                </Button>
              </Card>
              <Card>
                <h4>1st July, 2023</h4>
                <h5>
                  <strong>
                    NDIS pricing changes for 2023-24: what you need to know
                  </strong>
                </h5>
                <h4>
                  {truncateText(
                    "The National Disability Insurance Agency (NDIA) has released its pricing arrangements and price limits for the year 2023-24, following their Annual Pricing Review. Effective from July 1st, 2023, the following pricing adjustments will come into effect. NDIS participants’ plans will also be indexed to accommodate these changes.",
                    188
                  )}
                </h4>
                <Button onClick={() => handleNavigate("NDIS")}>
                  Learn More
                </Button>
              </Card>
            </div>
          </div>
          <div className="blog-section-mobile-container">
            <h3 className="blog-main-header">Blogs</h3>
            <h4 className="blog-main-subtext">
              Insights and Tips for Your Family’s Health and Well-being
            </h4>
            <h3 className="blog-second-header">News</h3>
            <div className="d-flex flex-column align-items-center blog-news-cards">
              <Card>
                <h4 className="blog-card-header">22nd March, 2024</h4>
                <h5 className="blog-card-subtitle">
                  <strong>
                    Important update: Changes to NDIS payment processes
                  </strong>
                </h5>
                <h4 className="blog-card-subtext">
                  The NDIA have announced that moving forward they are going to
                  be checking all payments more closely which means that
                  invoices will take a little longer to be approved and paid.
                </h4>
                <Button onClick={() => handleNavigate("Important")}>
                  Learn More
                </Button>
              </Card>
              <Card>
                <h4 className="blog-card-header">1st July, 2023</h4>
                <h5 className="blog-card-subtitle">
                  <strong>
                    NDIS pricing changes for 2023-24: what you need to know
                  </strong>
                </h5>
                <h4 className="blog-card-subtext">
                  {truncateText(
                    "The National Disability Insurance Agency (NDIA) has released its pricing arrangements and price limits for the year 2023-24, following their Annual Pricing Review. Effective from July 1st, 2023, the following pricing adjustments will come into effect. NDIS participants’ plans will also be indexed to accommodate these changes.",
                    188
                  )}
                </h4>
                <Button onClick={() => handleNavigate("NDIS")}>
                  Learn More
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <AssistSection />
      <Footer />
    </>
  );
}

export default Blog;
