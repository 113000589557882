import "./index.scss";

function HospitalToHomeDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Enhanced Care After Discharge</h1>
        <p>
          The transition from hospital to home recovery can be complex and
          challenging. Our Hospital to Home Service is designed to simplify this
          process by providing essential care and assistance tailored to your
          specific requirements after a hospital stay.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>
        <p>
          Our dedicated team is committed to assisting your smooth transition
          back home, by customizing our services to align with your recovery
          needs and preferences. We ensure you receive the necessary support to
          enhance your recovery potential comfortably at your own home. Type of
          support we provide:
        </p>
        <h2>1. Management of Wounds and Pain Relief:</h2>
        <ul>
          <li>
            Specialized care focusing on post-surgical wound care and effective
            pain relief strategies
          </li>
        </ul>
        <h2>2. Management of Medications:</h2>
        <ul>
          <li>
            Ensuring you are managing your medication effectively, with
            scheduling and reminders to maintain your treatment regimen.
          </li>
        </ul>
        <h2>3. Transportation and Medical Follow-Up Coordination:</h2>
        <ul>
          <li>
            Arranging transport for ongoing medical check-ups and setting up
            home visits from medical professionals.
          </li>
        </ul>

        <h2>4. Home Assistance Services:</h2>
        <ul>
          <li>
            Providing help with daily chores such as food preparation,
            housekeeping, clothes washing, and yard maintenance.
          </li>
        </ul>

        <h2>5. Hygiene and Personal Assistance:</h2>
        <ul>
          <li>
            Support with daily self-care activities such as showering, getting
            dressed, and managing toiletry needs.
          </li>
        </ul>

        <h2>6. Temporary Housing Support:</h2>
        <ul>
          <li>
            Facilitating temporary living arrangements if needed, ensuring a
            safe and conducive environment for recovery.
          </li>
        </ul>
        <h2>7. Intermittent Care Services:</h2>
        <ul>
          <li>
            Offering temporary relief for caregivers, allowing them to recharge
            while ensuring continuous care for you.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HospitalToHomeDetails;
