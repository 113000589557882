import "./index.scss";

function LifeSkillsDevelopmentDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Building Independence in the Community</h1>
        <p>
          At Famycare, we understand that developing life skills is essential
          for living independently and actively participating in the community.
          Our experienced team is dedicated to crafting a personalized program
          tailored to your unique needs, helping you acquire the skills
          necessary for self-reliance and community engagement.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>
        <p>
          With our extensive experience in the disability sector, Famycare is
          equipped to provide reliable, flexible, and meaningful support.
          Whether you need help with everyday tasks or complex skill-building,
          we are here to ensure your development is both comprehensive and
          enjoyable.
        </p>
        <h2>1. Meal Planning and Preparation</h2>
        <ul>
          <li>
            Learn to create nutritious meal plans, shop for groceries, and cook
            healthy meals, promoting dietary independence
          </li>
        </ul>
        <h2>2. Health and Wellbeing Management</h2>
        <ul>
          <li>
            Gain skills in managing personal healthcare, understanding
            medication regimes, and maintaining overall wellness.
          </li>
        </ul>
        <h2>3. Travel and Transportation</h2>
        <ul>
          <li>
            Acquire knowledge about road safety, and learn how to navigate and
            utilize public transport effectively.
          </li>
        </ul>

        <h2>4. Social Skills and Network Building</h2>
        <ul>
          <li>
            Develop abilities to build and maintain social networks and
            friendships, enhancing your social life and community involvement.
          </li>
        </ul>

        <h2>5. Accommodation Maintenance</h2>
        <ul>
          <li>
            Skills for keeping your living environment clean, organized, and
            maintained.
          </li>
        </ul>

        <h2>6. Financial Literacy and Management</h2>
        <ul>
          <li>
            Training in budgeting, managing finances, and understanding
            financial documents to ensure economic self-sufficiency.
          </li>
        </ul>
        <h2>7. Literacy and Numeracy</h2>
        <ul>
          <li>
            Enhance basic reading, writing, and arithmetic skills necessary for
            daily interactions and transactions.
          </li>
        </ul>
        <h2>8. Engagement with Government Services</h2>
        <ul>
          <li>
            Learn how to liaise effectively with government departments, access
            services, and understand your entitlements.
          </li>
        </ul>
        <h2>9. Bill Management</h2>
        <ul>
          <li>
            Acquire the skills needed to manage and pay bills on time,
            preventing financial issues
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LifeSkillsDevelopmentDetails;
