import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../assets/images/sidebar_cross.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccomodationMobileIcon from "../../assets/images/accomodation_mobile_icon.svg";
import CommunityMobileIcon from "../../assets/images/community_mobile_icon.svg";
import DailyMobileIcon from "../../assets/images/daily_mobile_icon.svg";
import HospitalMobileIcon from "../../assets/images/hospital_mobile_icon.svg";
import IndividualMobileIcon from "../../assets/images/individual_mobile_icon.svg";
import LifeMobileIcon from "../../assets/images/life_mobile_icon.svg";
import NursingMobileIcon from "../../assets/images/nursing_mobile_icon.svg";
import SupportMobileIcon from "../../assets/images/support_mobile_icon.svg";
import "./Sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { MenuProps } from "@mui/material/Menu";
import {
  Menu,
  MenuItem,
  Select,
  Typography,
  alpha,
  styled,
} from "@mui/material";

type Anchor = "left";

interface LeftDrawerProps {
  open: boolean;
  onClose: () => void;
  menuItems: {
    key: string;
    value: string;
    route: string;
    href: string;
    image: string;
  }[];
  scrollToSection: any;
  homeRef?: any;
  aboutRef?: any;
  contactRef?: any;
  careerRef?: any;
  blogRef?: any;
  accomodationRef?: any;
  communityRef?: any;
  dailyRef?: any;
  hospitalRef?: any;
  individualRef?: any;
  lifeRef?: any;
  nursingRef?: any;
  supportedRef?: any;
}

const LeftDrawer: React.FC<LeftDrawerProps> = ({
  open,
  onClose,
  menuItems,
  scrollToSection,
  homeRef,
  aboutRef,
  contactRef,
  careerRef,
  blogRef,
  accomodationRef,
  communityRef,
  dailyRef,
  hospitalRef,
  individualRef,
  lifeRef,
  nursingRef,
  supportedRef,
}) => {
  // const navigate = useNavigate();

  const handleNavigation = (e: any) => {
    console.log(e);
  };

  const handlePdf = () => {
    window.open("/famycare_referral_pdf.pdf", "_blank");
  };

  const [openServiceDropdown, setOpenServiceDropdown] = useState(false);

  const handleServiceDropdown = () => {
    setOpenServiceDropdown(!openServiceDropdown);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const openServiceDropdown = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      width: "100%",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const list = () => (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
      }}
      role="presentation"
      // onClick={onClose}
      onKeyDown={onClose}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "1rem 1.5rem 0.45rem 1.5rem" }}
        // style={{ backgroundColor: "#FFE200" }}
      >
        {/* <Button>
          <img
            src={MalayaliBeerLogoMobile}
            alt=""
            className="malayali-beer-logo-mobile"
          />
        </Button> */}
        <Typography
          variant="h4"
          className="sidebar-top-text"
          component={Link}
          to={"/home"}
          sx={{ textDecoration: "none" }}
        >
          Home
        </Typography>
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            padding: "8px", // Adjust the padding as needed
          }}
        >
          <img src={CloseIcon} alt="" />
        </IconButton>
      </div>
      <List>
        {menuItems.map((item) =>
          item.key === "Services" ? (
            <>
              <ListItem
                key={item.key}
                disablePadding
                onClick={handleServiceDropdown}
              >
                <ListItemButton
                  sx={{ flexGrow: 0 }}
                  className="main-btns"
                  // component={Link} to={item.route}
                >
                  <ListItemText primary={item.value} className="orange-text" />
                  {!openServiceDropdown ? (
                    <KeyboardArrowDownIcon sx={{ color: "#025D68" }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ color: "#025D68" }} />
                  )}
                </ListItemButton>
              </ListItem>
              {openServiceDropdown && (
                <div>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/accomodation-support"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img
                        src={AccomodationMobileIcon}
                        alt=""
                        className="me-2"
                      />
                      <Typography className="orange-text">
                        Accommodation Support
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/assistance-in-daily-living-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={DailyMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Assistance in Daily Living
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/development-of-life-skills-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={LifeMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Development of Life Skills
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/hospital-to-home-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={HospitalMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Hospital to Home Services
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/individualised-living-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={IndividualMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Individualised Living Options
                      </Typography>
                      (ILO)
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/nursing-care"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={NursingMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Nursing & Complex Care
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/participation-in-community-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={CommunityMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Participation in Community
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/supported-independent-living-service"}
                      sx={{ padding: "0.875rem 2.125rem" }}
                    >
                      <img src={SupportMobileIcon} alt="" className="me-2" />
                      <Typography className="orange-text">
                        Supported Independent Living
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </div>
              )}

              {/* <ListItem
                key={item.key}
                disablePadding
                onClick={(e) => {
                  scrollToSection(malayaliRef);
                }}
              >
                <ListItemButton
                // component={Link} to={item.route}
                >
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem> */}
            </>
          ) : item.key === "About" ? (
            <>
              <ListItem key={item.key} disablePadding>
                <ListItemButton
                  component={Link}
                  to={item.route}
                  className="main-btns"
                >
                  <ListItemText primary={item.value} className="orange-text" />
                </ListItemButton>
              </ListItem>
            </>
          ) : item.key === "Contact" ? (
            <ListItem key={item.key} disablePadding>
              <ListItemButton
                component={Link}
                to={item.route}
                className="main-btns"
              >
                <ListItemText primary={item.value} className="orange-text" />
              </ListItemButton>
            </ListItem>
          ) : item.key === "Referral" ? (
            <ListItem key={item.key} disablePadding>
              <ListItemButton className="main-btns" onClick={handlePdf}>
                <ListItemText primary={item.value} />
              </ListItemButton>
            </ListItem>
          ) : item.key === "Blog" ? (
            <ListItem key={item.key} disablePadding>
              <ListItemButton
                component={Link}
                to={item.route}
                className="main-btns"
              >
                <ListItemText primary={item.value} className="orange-text" />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={item.key} disablePadding>
              <ListItemButton
                component={Link}
                to={item.route}
                className="main-btns"
                // component={Link} to={item.route}
              >
                <ListItemText primary={item.value} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      {/* <div className="sidebar-btn-container">
        <Button
          className="dealer-sidebar-btn"
          variant="outlined"
          onClick={() => handleNavigation}
        >
          <span>Find a Dealer</span>
        </Button>
        <Button
          className="contact-btn"
          variant="contained"
          onClick={() => handleNavigation}
        >
          <span>Contact Us</span>
        </Button>
      </div> */}
    </Box>
  );

  return (
    <Drawer anchor="left" open={open} onClose={onClose} className="left-drawer">
      {list()}
    </Drawer>
  );
};

export default LeftDrawer;
