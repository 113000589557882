import Card from "@mui/material/Card";
import React from "react";
import MissionIcon from "../../../assets/images/mission_icon.svg";
import VisionIcon from "../../../assets/images/vision_icon.svg";
import "./index.scss";

function ChartSection() {
  return (
    <div className="row">
      <div className="col-xs-12">
        <div
          className="chart-section-pc-container"
          style={{ marginBottom: "13.5625rem" }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "10%" }}
          >
            <Card
              className="text-center mission-card"
              sx={{
                bgcolor: "#C9DADC",
                padding: "40px 31px 61px 29px",
                borderRadius: "20px",
                maxWidth: "460px",
              }}
            >
              <img src={MissionIcon} alt="" />
              <h4>Our Mission</h4>
              <h5>
                To deliver comprehensive NDIS services that empower people with
                disabilities to make informed choices, gain independence, and
                live fulfilling lives
              </h5>
            </Card>
            <Card
              className="text-center vision-card"
              sx={{
                bgcolor: "#C9DADC",
                padding: "40px 42px 43px 42px",
                borderRadius: "20px",
                maxWidth: "460px",
              }}
            >
              <img src={VisionIcon} alt="" />
              <h4>Our Vision</h4>
              <h5>
                We provide disability and nursing care services for eligible
                people to enhance their quality of life with more time with
                friends and family, increased independence, access to new skills
                and jobs, and community service opportunities.
              </h5>
            </Card>
          </div>
        </div>
        <div className="chart-section-mobile-container">
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ gap: "10%" }}
          >
            <Card
              className="text-center mission-card"
              sx={{
                bgcolor: "#C9DADC",
                padding: "40px 31px 61px 29px",
                borderRadius: "20px",
                maxWidth: "460px",
              }}
            >
              <img src={MissionIcon} alt="" />
              <h4>Our Mission</h4>
              <h5>
                To deliver comprehensive NDIS services that empower people with
                disabilities to make informed choices, gain independence, and
                live fulfilling lives
              </h5>
            </Card>
            <Card
              className="text-center vision-card"
              sx={{
                bgcolor: "#C9DADC",
                padding: "40px 42px 43px 42px",
                borderRadius: "20px",
                maxWidth: "460px",
              }}
            >
              <img src={VisionIcon} alt="" />
              <h4>Our Vision</h4>
              <h5>
                We provide disability and nursing care services for eligible
                people to enhance their quality of life with more time with
                friends and family, increased independence, access to new skills
                and jobs, and community service opportunities.
              </h5>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartSection;
