import React from "react";
import AccommodationImg from "../../../assets/images/nurse-with-nurse-wheelchair-nurse-background 1.png";
import "./index.scss";
import Button from "@mui/material/Button";
import MessageIcon from "../../../assets/images/message_icon.svg";
import CallIcon from "../../../assets/images/call_icon.svg";
import Navbar from "../../Navbar";
import Footer from "../../Home/Footer";
import AssistSection from "../../Home/AssistSection";
import NursingCareBanner from "./banner-section";
import SupportedIndependentLivingBanner from "./banner-section";
import SupportedIndependentLivingDetails from "./details-section";
import SEO from "../../SEO";

function SupportedIndependentLiving() {
  return (
    <>
      <SEO
        title="Supported Independent Living (SIL)"
        description="Tailored support for daily activities and personal care, promoting independent living in shared or individual homes."
        keywords="support, activities, care, independent, living, homes"
        image="../../../assets/images/realistic-scene-with-elderly-care-senior-people 2.png"
        url="https://www.famycare.com.au/supported-independent-living-service"
      />
      <div className="row home-container">
        <div className="col-xs-12">
          <Navbar />
          <SupportedIndependentLivingBanner />
          <SupportedIndependentLivingDetails />
          <AssistSection />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default SupportedIndependentLiving;
