import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Home from "./layout/components/Home";
import AboutUs from "./layout/components/AboutUs";
import Blog from "./layout/components/Blog";
import ImportantUpdate from "./layout/components/Blog/ImportantUpdate";
import NDISPricingChanges from "./layout/components/Blog/NDISPricingChanges";
import ContactUs from "./layout/components/ContactUs";
import AccommodationSection from "./layout/components/OurServices/AccomodationAssistance";
import NursingCare from "./layout/components/OurServices/Nursing&ComplexCare";
import CommunityParticipationSection from "./layout/components/OurServices/CommunityParticipation";
import LifeSkillsDevelopment from "./layout/components/OurServices/LifeSkillsDevelopment";
import IndividualisedLiving from "./layout/components/OurServices/IndividualisedLiving";
import DailyLivingAssistance from "./layout/components/OurServices/DailyLivingAssistance";
import HospitalToHomeProgram from "./layout/components/OurServices/HospitalToHomes";
import SupportedIndependentLiving from "./layout/components/OurServices/SupportedIndependent";
import Career from "./layout/components/Career";
import ScrollToTop from "./layout/components/Home/ScrollToTop";

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop />
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/accomodation-support"
            element={<AccommodationSection />}
          />
          <Route
            path="/participation-in-community-service"
            element={<CommunityParticipationSection />}
          />

          <Route
            path="/development-of-life-skills-service"
            element={<LifeSkillsDevelopment />}
          />
          <Route
            path="/individualised-living-service"
            element={<IndividualisedLiving />}
          />
          <Route
            path="/assistance-in-daily-living-service"
            element={<DailyLivingAssistance />}
          />
          <Route
            path="/hospital-to-home-service"
            element={<HospitalToHomeProgram />}
          />
          <Route
            path="/supported-independent-living-service"
            element={<SupportedIndependentLiving />}
          />
          <Route path="/nursing-care" element={<NursingCare />} />

          <Route path="/blog" element={<Blog />} />
          <Route
            path="/blog/NDIS-pricing-changes"
            element={<NDISPricingChanges />}
          />

          <Route path="/blog/Important-update" element={<ImportantUpdate />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
