import { Button, Card } from "@mui/material";
import React from "react";
import HospitalIcon from "../../../assets/images/hospital_icon.png";
import "./index.scss";

function MissionSection() {
  return (
    <>
      <div className="ndis-section">
        <div className="ndis-logo">
          <img src="ndis.png" alt="We Love NDIS Logo" />
        </div>
        <div className="ndis-text">
          <h2>Registered NDIS Provider</h2>
          <p>
            Famycare is a trusted Registered NDIS Provider
            , dedicated to guiding
            you through a wide range of personalized support services. Reach out
            to us today for all your NDIS service needs – we're here to help!
          </p>
        </div>
      </div>
      <div className="mission-section-pc-container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="mission-header">
              <h3>Registered NDIS Provider</h3>
            </div>
            <div className="mission-middle-section">
              <h5>Famycare, founded and operated by Registered Nurses.</h5>
              <h5>
                We are professionally qualified, experienced, and trained teams
                with over 20 years of experience in nursing and disability
                support in the healthcare and disability sectors. We are here to
                help and support participants with complex needs, whether they
                require high-level care or challenging behaviours.
              </h5>
            </div>
          </div>
          <div>
            <Card className="hospital-card">
              <div className="text-center hospital-card-inner">
                <img src={HospitalIcon} alt="" />
                <p>Founded and operated By</p>
                <h5>Registered Nurses</h5>
                <p>Following COVID-19 Procedures</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="mission-section-mobile-container">
        <div className="d-flex flex-column justify-content-between align-items-center">
          <div>
            <div className="mission-header">
              <h3>Registered NDIS Provider</h3>
            </div>
            <div className="mission-middle-section">
              <h5>
                We are professionally qualified, experienced, and trained teams
                with over 20 years of experience in nursing and disability
                support in the healthcare and disability sectors. We are here to
                help and support participants with complex needs, whether they
                require high-level care or challenging behaviours.
              </h5>
            </div>
          </div>
          <div>
            <Card className="hospital-card">
              <div className="text-center hospital-card-inner">
                <img src={HospitalIcon} alt="" />
                <p>Founded and operated By</p>
                <h5>Registered Nurses</h5>
                <p>Following COVID-19 Procedures</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionSection;
