import "./index.scss";

function SupportedIndependentLivingDetails() {
  return (
    <div className="container-fluid accommodation_Details_banner">
      <div className="row details_heading_row">
        <h1>Empowering Independence with Supported Independent Living (SIL)</h1>
        <p>
          Supported Independent Living (SIL) assists people with disabilities in
          living more independently. SIL is ideal for those with high support
          needs who want to maximize their independence. It can be provided to
          individuals living either in their family home or in a shared home
          environment.
        </p>
      </div>
      <div className="row details_content_row">
        <h1>How much Support is Provided?</h1>
        <p>
          The amount of SIL support you receive will depend on your unique
          requirements such as:
        </p>
        <h2>1. Unique Situation and Requirements: </h2>
        <ul>
          <li>
            Aligning support with your individual goals and desired choices.
          </li>
        </ul>
        <h2>2. Personal Objectives and Preferences:</h2>
        <ul>
          <li>
            Ensuring you are managing your medication effectively, with
            scheduling and reminders to maintain your treatment regimen.
          </li>
        </ul>
        <h2>3. Life Stage: </h2>
        <ul>
          <li>Customizing assistance based on your current age group.</li>
        </ul>

        <h2>4. Self-Sufficiency Capabilities:</h2>
        <ul>
          <li>Evaluating your ability to manage tasks independently.</li>
        </ul>

        <h2>5. Existing Skills and Development Areas: </h2>
        <ul>
          <li>Reviewing present skills and highlighting areas for growth.</li>
        </ul>

        <h2>6. Home Adjustments or Assistive Tools Needed:</h2>
        <ul>
          <li>
            Considering necessary modifications or devices to enhance
            independence.
          </li>
        </ul>
      </div>
      <div className="row empty_row"></div>
      <div className="row details_content_row">
        <h1>What type of Support is provided?</h1>

        <h2>1. Assistance in Daily Living: </h2>
        <ul>
          <li>
            Help with cooking, cleaning, personal hygiene, and medication
            management.
          </li>
        </ul>
        <h2>2. Skill Building: </h2>
        <ul>
          <li>
            Support in managing household tasks, budgeting, social skills, and
            communication.
          </li>
        </ul>
        <h2>3. Community Engagement:</h2>
        <ul>
          <li>
            Opportunities to participate in social outings, hobbies, and local
            programs.
          </li>
        </ul>

        <h2>4. Behavior Support:</h2>
        <ul>
          <li>
            Implementation of behavior support plans to encourage positive
            behaviors.
          </li>
        </ul>

        <h2>5. Health and Safety:</h2>
        <ul>
          <li>
            Assistance with health management, fall prevention, and dietary
            needs.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SupportedIndependentLivingDetails;
